import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import { useSlateSerialiser } from "../hooks/useSlateSerialiser"
import parse from "html-react-parser"
import SEO from "../layout/seo"
import Layout from "../layout"

const MainContainer = tw.div`
container mx-auto pt-12 mb-24
`
const ContentContainer = tw.article`
mx-auto mb-3 text-gray-800 px-2 w-3/4 prose sm:prose lg:prose-lg 
`

const Heading = tw.h1`
w-3/4 mx-auto leading-tight mb-3 sm:mb-4 md:mb-5 lg:mb-6 px-2 text-left text-3xl md:text-5xl
`
const SubHeading = tw.p`
px-2 text-left prose prose-lg sm:prose-xl
`

const PrivacyPage = () => {
  const data = useStaticQuery(graphql`
    query PrivacyPageContent {
      webinyHeadlessCms {
        getPageHeadings(where: { page: "Privacy" }) {
          data {
            id
            heading
            subHeading
            pageCopy
          }
        }
      }
    }
  `)

  const header = data.webinyHeadlessCms.getPageHeadings.data
  const policyHTML = useSlateSerialiser(
    data.webinyHeadlessCms.getPageHeadings.data.pageCopy
  )
  return (
    <>
      <SEO title="Data Privacy and Cookies" description="" />
      <Layout>
        <MainContainer>
          <Heading>{header.heading}</Heading>
          <SubHeading>{header.subHeading}</SubHeading>
          <ContentContainer>{parse(policyHTML)}</ContentContainer>
        </MainContainer>
      </Layout>
    </>
  )
}

export default PrivacyPage
